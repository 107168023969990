<template>
  <div>
    <div>
      <OpenAppHead :options="options" @getshowimg="getshowimg" />
    </div>
    <div class="content" :style="{backgroundImage:'url('+bgc+')',marginTop:ContentMarginTop + 'px'}">
      <!--        生活精选-->
      <div class="lifeSelectWrap" :style="{backgroundImage:'url('+localBgc+')'}">
        <div style="display: flex;flex-wrap: wrap;">
          <div
            v-for="(item,index) in lifeSelectList"
            :key="index"
            class="lifeSelectContent"
            :class="index/3===1?'':'lifeSelectContent_c'"
          >
            <div v-if="SystemVersion() === 'weixin'">
              <div v-if="item.redirectType === 230">
                <div class="lifeSelects" style="">
                  <img class="lifeSelectImg" :src="item.icon" alt="">
                  <p class="lifeSelectText">{{ item.name }}</p>
                  <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
                </div>
                <localLifeIndex :opendata="item.openAppdata" class="wxStyle" />
              </div>
              <div v-else>
                <div class="lifeSelects" style="" @click="goLife(item,index)">
                  <img class="lifeSelectImg" :src="item.icon" alt="">
                  <p class="lifeSelectText">{{ item.name }}</p>
                  <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="lifeSelects" style="" @click="goLife(item,index)">
                <img class="lifeSelectImg" :src="item.icon" alt="">
                <p class="lifeSelectText">{{ item.name }}</p>
                <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--            连接icon-->
      <div class="ConnectionServiceWrap">
        <img v-for="(item,index) in 2" :key="index" :src="ConnectionServiceIcon" alt="" class="ConnectionServiceIcon">
      </div>
      <!--            出行服务-->
      <div class="TravelServiceWrap" :style="{backgroundImage:'url(' + TravelServiceBgc + ')' }">
        <div class="lifeServiceTitle" :style="{backgroundImage:'url(' + TravelServiceIcon + ')'}">
          {{ TravelServiceTitle }}
        </div>
        <div class="FoodPremiumContent">
          <div v-if="SystemVersion() === 'weixin'" class="FoodPremiumIconList">
            <div v-for="(item,index) in TravelServiceList" :key="index" class="FoodPremiumIconListContent">
              <div class="lifeSelects">
                <img class="lifeSelectImg" :src="item.icon" alt="">
                <p class="lifeSelectText">{{ item.name }}</p>
                <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
              </div>
              <localLifeIndex :opendata="item.openAppdata" class="wxStyle" />
            </div>
          </div>
          <div v-else class="FoodPremiumIconList">
            <div
              v-for="(item,index) in TravelServiceList"
              :key="index"
              class="FoodPremiumIconListContent"
              @click="goFoodPremium(item)"
            >
              <img class="lifeSelectImg" :src="item.icon" alt="">
              <p class="lifeSelectText">{{ item.name }}</p>
              <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
            </div>
          </div>
        </div>
      </div>

      <!--            美食优惠-->
      <div class="FoodPremiumWrap" :style="{backgroundImage:'url(' + FoodPremiumBgc + ')' }">
        <div class="lifeServiceTitle" :style="{backgroundImage:'url(' + FoodPremiumIcon + ')'}">{{ FoodPremiumTitle }}
        </div>
        <div class="FoodPremiumContent">
          <div class="FoodPremiumIconList">
            <!--                        -->
            <div v-for="(item,index) in FoodPremiumList" :key="index" class="FoodPremiumIconListContent">
              <div v-if="item.isDepartureWx === true">
                <div>
                  <img class="lifeSelectImg" :src="item.icon" alt="">
                  <p class="lifeSelectText">{{ item.name }}</p>
                  <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
                </div>
                <localLifeIndex :opendata="item.openAppdata" class="wxStyle" />
              </div>
              <div v-else @click="goFoodPremium(item,index)">
                <img class="lifeSelectImg" :src="item.icon" alt="">
                <p class="lifeSelectText">{{ item.name }}</p>
                <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
              </div>
            </div>
          </div>
          <div class="FoodPremiumList">
            <div
              v-for="(item,index) in FoodPremiumLists"
              :key="index"
              class="FoodPremiumListContent"
              :class="index === 1?'FoodPremiumListContent1':''"
              @click="goFoodPremium(item)"
            >
              <img :src="item.icon" alt="">
              <localLifeIndex v-if="item.isDepartureWx === true" :opendata="item.openAppdata" class="wxStyle" />
            </div>
          </div>
        </div>
      </div>
      <!--         生活服务   -->
      <div class="lifeServiceWrap" :style="{backgroundImage:'url(' + serviceBgc + ')'}">
        <div class="lifeServiceTitle" :style="{backgroundImage:'url(' + serviceTitleBgc + ')'}">{{ lifeServiceTitle }}
        </div>
        <div v-if="SystemVersion() === 'weixin'" class="FoodPremiumIconList">
          <div v-for="(item,index) in LifeServiceLists" :key="index" class="FoodPremiumIconListContent">
            <div>
              <img class="lifeSelectImg" :src="item.icon" alt="">
              <p class="lifeSelectText">{{ item.name }}</p>
              <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
            </div>
            <localLifeIndex v-if="item.isDepartureWx === true" :opendata="item.openAppdata" class="wxStyle" />
          </div>
        </div>
        <div v-else class="FoodPremiumIconList">
          <div
            v-for="(item,index) in LifeServiceLists"
            :key="index"
            class="FoodPremiumIconListContent"
            @click="goFoodPremium(item)"
          >
            <img class="lifeSelectImg" :src="item.icon" alt="">
            <p class="lifeSelectText">{{ item.name }}</p>
            <p class="lifeSelectDesc"><span>{{ item.desc }}</span></p>
          </div>
        </div>
        <div class="lifeServiceContent">
          <div v-if="SystemVersion() === 'weixin'" style="display: flex;flex-wrap: wrap;">
            <div
              v-for="(item,index) in lifeServiceList"
              :key="index"
              class="lifeServices"
              :class="index%2===0 ? 'lifeServices_m' : ''"
            >
              <div v-if="item.isDepartureWx === true" class="lifeServicesOne">
                <div>
                  <div class="ServiceTitle">
                    <span class="ServiceTitleName">{{ item.name }}</span>
                    <span class="ServiceTitleDesc">{{ item.desc }}</span>
                  </div>
                  <img class="ServiceContent" :src="item.icon" alt="">
                </div>
                <localLifeIndex :opendata="item.openAppdata" class="wxStyle" />
              </div>
              <div v-else @click="goLifeService(item)">
                <div class="ServiceTitle">
                  <span class="ServiceTitleName">{{ item.name }}</span>
                  <span class="ServiceTitleDesc">{{ item.desc }}</span>
                </div>
                <img class="ServiceContent" :src="item.icon" alt="">
              </div>
            </div>
          </div>
          <div v-else style="display: flex;flex-wrap: wrap;">
            <div
              v-for="(item,index) in lifeServiceList"
              :key="index"
              class="lifeServices"
              :class="index%2===0 ? 'lifeServices_m' : ''"
              @click="goLifeService(item)"
            >
              <div class="ServiceTitle">
                <span class="ServiceTitleName">{{ item.name }}</span>
                <span class="ServiceTitleDesc">{{ item.desc }}</span>
              </div>
              <img class="ServiceContent" :src="item.icon" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--        <Nav></Nav>-->
    <!--下载app二维码-->
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="../../assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>
    <!--会员弹窗-->
    <van-dialog
      v-model="isMembership"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="dia_box">
        <div class="dai_title">
          <img
            class="dialogBgc"
            src="../../assets/images/dialogBgc.png"
            alt=""
          >
          <div class="dialogText">
            <p class="dialogText1">等级不足</p>
            <p class="dialogText2">本权益为悦淘vip专享</p>
            <p class="dialogText3">
              需升级您的身份才可领取哦
            </p>
          </div>
        </div>
        <div class="dia_btn">
          <div @click="NotUpgraded">暂不升级</div>
          <div @click="ImmediateUpgrade">立即升级</div>
        </div>
      </div>
    </van-dialog>
    <!--复制链接弹窗-->
    <div v-if="showCopyLink" class="mask">
      <div class="showCopyLinkContent">
        <img :src="InterceptionImg" alt="" class="showCopyLinkImg" @click="copyLink">
        <br>
        <img
          src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/21/yuelvhuiSeEpBaYYQm1608644479.png"
          alt=""
          class="showCopyLinkClose"
          @click="closeMask"
        >
      </div>
    </div>
    <!--微信弹窗-->
    <!--        <div class="wxStyles" v-html="idHtml" v-if="goAPP"></div>-->
  </div>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast } from 'vant'

Vue.use(Toast).use(Dialog)
// import Nav from '@/component/Nav';
import {
  getLocalLifeHomeIcon,
  getKFCUrl
} from '@/services/comeOn.js'
import {
  getEquityType
} from '@/services/userApi'
import OpenAppHead from '../../component/GoodsDetail/openApp'
import localLifeIndex from '@/component/wxOpenAppTem/localLifeIndex'
import { wakeApp, SystemVersion } from '@/utils/public.js'
import { wxConfigInit, wxConfigWakeApp } from '@/utils/wx-api.js'
import { isWeChat } from '@/utils/common/validate'

Vue.config.ignoredElements = ['wx-open-launch-app']
export default {
  name: 'HomeIndex',
  components: {
    OpenAppHead,
    localLifeIndex
  },
  data() {
    return {
      id: 'id',
      idHtml: '',
      goAPP: false,
      info: 'https://www.yuetao.vip/ytApp/openPage?redirectType=46',

      openAppdata: {
        text: '立即打开',
        wxExtinfo: {
          product_type: this.product_type
        },
        handleError: (e) => {
        },
        handleLaunch: (e) => {
        }
      },
      mid: '',
      bgc: '', // 首页背景图
      localBgc: '', // 生活精选背景图
      lifeSelectList: [], // 生活精选iconList
      ConnectionServiceIcon: '', // 连接icon
      TravelServiceBgc: '', // 出行服务背景图
      TravelServiceIcon: '', // 出行服务icon
      TravelServiceTitle: '', // 出行服务title
      TravelServiceList: '', // 出行服务list
      FoodPremiumBgc: '', // 美食优惠背景图
      FoodPremiumIcon: '', // 美食优惠icon
      FoodPremiumList: [], // 美食优惠iconlist
      FoodPremiumTitle: '', // 美食优惠title
      FoodPremiumLists: [], // 美食优惠list
      serviceBgc: '', // 生活服务背景图
      lifeServiceTitle: '', // 生活服务icon
      lifeServiceList: [], // 生活服务iconList
      LifeServiceLists: [], // 生活服务list
      serviceTitleBgc: '', // 生活服务title背景图
      showimg: false,
      ContentMarginTop: '0',
      topclose: true,
      isMembership: false,
      showCopyLink: false,
      InterceptionImg: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-22/22/yuelvhuijE6PwGSqSU1608647270.png'
      // isWxStyle:false,
    }
  },
  computed: {
    options() {
      const obj = {
        showtig: true
      }
      const info = {
        product_type: 229
      }
      obj.info = info
      return obj
    }

  },
  created() {
    this.mid = window.localStorage.getItem('uid')
    this.$nextTick(() => {
      // this.$store.dispatch('wxConfigWakeApp', {})
      wxConfigWakeApp()
    })
    if (localStorage.getItem('oilName')) {
      localStorage.removeItem('oilName')
    }
  },
  mounted() {
    this.isWxStyle = isWeChat()
    this.getEquityType()// 更新会员等级
    this.configShare()// 分享配置
    this.getHomeIcon()// 本地生活首页icon
  },
  methods: {
    // 会员等级
    getEquityType() {
      getEquityType({
        mid: this.mid
      }).then((res) => {
        if (Number(res.code) === 200) {
          window.localStorage.setItem('memberType', res.data.memberType)// 0注册会员  1 金卡会员 2 白金会员 3 黑金会员
        } else {
          Toast(res.msg)
        }
      })
    },
    SystemVersion() {
      return SystemVersion('isWeixin')
    },
    // 监听error 函数
    handleErrorFn(e) {
      // 跳转失败
      // download() // 这里跳转App下载页面
    },
    // 监听launch 函数
    handleLaunchFn(e) {
      // 跳转成功
    },
    // 暂不升级（跳转倒本地生活首页）
    NotUpgraded() {
      this.isMembership = false
    },
    // 立即升级（跳转倒会员升级页）
    ImmediateUpgrade() {
      this.$router.push({
        path: '/MustBuy'
      })
    },
    isclose(t) {
      this.topclose = t
    },
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    // getIcon
    getHomeIcon() {
      const that = this
      getLocalLifeHomeIcon({
        mid: window.localStorage.getItem('uid')
      }).then(res => {
        if (Number(res.code) === 200) {
          that.bgc = (res.data.background.img)[0]
          that.localBgc = (res.data.background.img)[1]
          const lifeData = res.data.list.life.data
          lifeData.forEach(e => { // 生活精选
            if (e.redirectType === 230) { // 饿了么
              e.isDepartureWx = true
              e.openAppdata = {
                text: e.name,
                wxExtinfo: {
                  product_type: e.redirectType
                  // isShow:true//true饿了么外卖fals饿了么买菜
                },
                handleError: (e) => {
                },
                handleLaunch: (e) => {
                }
              }
              if (e.redirectParams.id === 823) { // 饿了么买菜
                e.openAppdata.wxExtinfo.isShow = true
              } else {
                e.openAppdata.wxExtinfo.isShow = false
              }
            } else {
              e.isDepartureWx = false
            }
          })
          that.lifeSelectList = lifeData// 生活精选iconList
          that.ConnectionServiceIcon = (res.data.background.img)[2]// 连接icon
          that.TravelServiceBgc = (res.data.background.img)[5]// 出行服务背景图
          that.TravelServiceIcon = res.data.list.travel.icon// 出行服务icon
          that.TravelServiceTitle = res.data.list.travel.title// 出行服务title
          const travelList = res.data.list.travel.data// 出行服务
          travelList.forEach(e => {
            e.isDepartureWx = true
            e.openAppdata = {
              text: e.name,
              wxExtinfo: {
                product_type: e.redirectType,
                id: e.redirectParams.id
              },
              handleError: (e) => {
              },
              handleLaunch: (e) => {
              }
            }
          })
          that.TravelServiceList = travelList// 出行服务list
          that.FoodPremiumBgc = (res.data.background.img)[5]// 美食优惠背景图
          const FoodPremiumList = res.data.list.gourmet.data.both// 美食优惠
          FoodPremiumList.forEach(e => {
            if (e.redirectType === 96) { // 肯德基
              e.isDepartureWx = false
            } else {
              e.isDepartureWx = true
              e.openAppdata = {
                text: e.name,
                wxExtinfo: {
                  product_type: e.redirectType,
                  id: e.redirectParams.id,
                  redirectName: e.name
                },
                handleError: (e) => {
                },
                handleLaunch: (e) => {
                }
              }
            }
          })
          that.FoodPremiumTitle = res.data.list.gourmet.title// 美食优惠title
          that.FoodPremiumIcon = res.data.list.gourmet.icon// 美食优惠icon
          that.FoodPremiumList = FoodPremiumList// 美食优惠iconList
          const FoodPremiumLists = res.data.list.gourmet.data.image
          FoodPremiumLists.forEach(e => {
            e.isDepartureWx = true
            e.openAppdata = {
              text: e.name,
              wxExtinfo: {
                product_type: e.redirectType,
                id: e.redirectParams.id,
                redirectName: e.name
              },
              handleError: (e) => {
              },
              handleLaunch: (e) => {
              }
            }
          })
          that.FoodPremiumLists = res.data.list.gourmet.data.image// 美食优惠list
          that.serviceBgc = (res.data.background.img)[5]// 生活服务背景图
          that.lifeServiceTitle = res.data.list.service.title// 生活服务title
          that.serviceTitleBgc = res.data.list.service.icon// 生活服务title背景图
          const LifeServiceList = res.data.list.service.data.both// 生活服务list
          LifeServiceList.forEach(e => {
            e.isDepartureWx = true
            e.openAppdata = {
              text: e.name,
              wxExtinfo: {
                product_type: e.redirectType,
                id: e.redirectParams.id
              },
              handleError: (e) => {
              },
              handleLaunch: (e) => {
              }
            }
          })
          that.LifeServiceLists = LifeServiceList // 生活服务iconlist
          const lifeService = res.data.list.service.data.image// 生活服务list
          lifeService.forEach(e => {
            if (e.redirectType === 37 || e.redirectType === 666) {
              e.isDepartureWx = true
              e.openAppdata = {
                text: e.name,
                wxExtinfo: {
                  product_type: e.redirectType,
                  id: e.redirectParams.id
                },
                handleError: (e) => {
                },
                handleLaunch: (e) => {
                }
              }
            } else {
              e.isDepartureWx = false
            }
          })
          that.lifeServiceList = lifeService// 生活服务list
        } else {
          Toast(res.msg)
        }
      })
    },
    handleLaunch(e) {
    },
    handleError: (e) => {
    },
    // 生活精选页面跳转
    goLife(item, index) {
      const _this = this
      // const memberType = window.localStorage.getItem('memberType')
      // const ToastText = '请下载悦淘APP开通会员，享更多优惠'
      if (item.redirectType === 61) { // 加油
        // let obj = {
        //     product_type:item.product_type,
        //     redirectName:item.name,
        //     isHiddenShare:true,
        //     needLogin:true,
        // }
        // wakeApp(obj);
        // _this.$router.push({
        //   path: '/comeOnGuide',
        //   query: {}
        // })
        _this.$router.push({
          path: '/refueling-list',
          query: {}
        })
      } else if (item.redirectType === 96) { // 美团
        _this.$router.push({
          path: '/cps',
          query: {
            type: '4'
          }
        })
      } else if (item.redirectType === 230) { // 饿了么外卖/买菜
        // _this.$router.push({
        //     path:'/hungry',
        // })
        // this.showCopyLink = true;
        const obj = {
          product_type: item.product_type,
          redirectName: item.name,
          isHiddenShare: true,
          needLogin: true
        }
        wakeApp(obj)
      } else if (item.redirectType === 46) { // 话费
        // if (memberType === '0') {
        //  this.isMembership = true
        // } else {
        if (item.redirectParams.id === 461) { // 话费
          _this.$router.push({
            path: '/prerogativePay',
            query: {
              payType: 1,
              indexType: 2,
              homeGoIndex: 1,
              FromType: 1
            }
          })
        } else if (item.redirectParams.id === 462) { // 优酷
          _this.$router.push({
            path: '/prerogativePay',
            query: {
              payType: 1,
              indexType: 1,
              homeGoIndex: 2
            }
          })
        } else if (item.redirectParams.id === 463) { // 腾讯
          _this.$router.push({
            path: '/prerogativePay',
            query: {
              payType: 1,
              indexType: 1,
              homeGoIndex: 3
            }
          })
        } else if (item.redirectParams.id === 464) { // 爱奇艺
          _this.$router.push({
            path: '/prerogativePay',
            query: {
              payType: 1,
              indexType: 1,
              homeGoIndex: 4
            }
          })
        }
      } else if (item.redirectType === 111) { // 电影票
        _this.$router.push({
          path: '/Cinematicket_poster',
          query: {
            isHW: 0
          }
        })
      } else if (item.redirectType === 239) { // 信用卡
        _this.$router.push({
          path: '/Credit/creditcardapply',
          query: {}
        })
      } else if (item.redirectType === 246) { //
        _this.$router.push({
          path: '/KingCardIndex',
          query: {}
        })
      }
    },
    // 美食优惠页面跳转
    goFoodPremium(item) {
      if (item.redirectType === 96) { // 肯德基
        getKFCUrl({
          'uid': localStorage.getItem('uid'),
          'type': 5,
          'productNo': ''
        }).then(res => {
          if (Number(res.code) === 200) {
            window.location.replace(res.data.jumpLink)
          } else {
            Toast(res.msg)
          }
        })
      } else {
        const obj = {
          product_type: item.product_type,
          redirectName: item.name,
          isHiddenShare: true,
          needLogin: true
        }
        wakeApp(obj)
        // Toast('请下载悦淘APP，享更多优惠');
        // this.showCopyLink = true;
        // 麦当劳   redirectParams.id=64
        // 必胜客  redirectParams.id=24
        // 真功夫   redirectParams.id=149
        // 哈根达斯  redirectParams.id=26
        // 货拉拉   redirectParams.id=128
        // 闪电修   redirectParams.id=257
        // 顺丰    redirectParams.id=260
        // 盒马    redirectParams.id=78
        // 58到家  redirectParams.id=168
        // if(item.redirectParams.id === ''){
        //     item.redirectParams.id = 168;
        // }
        // let data = {
        //     mid:this.mid,
        //     channelId:1,//渠道id 悦淘1
        //     type:1,//1卡券2直冲
        //     productNo:item.redirectParams.id,//品牌id
        // }
        // getBrandLink(data).then(res=>{
        //     if(Number(res.code) === 200){
        //         window.location.replace(res.data.link);
        //     }else{
        //
        //     }
        // })
      }
    },
    // 生活服务页面跳转
    goLifeService(item) {
      const that = this
      if (item.redirectType === 239) {
        that.$router.push({
          path: '/Credit/creditcardapply',
          query: {}
        })
      } else if (item.redirectType === 228) {
        that.$store.state.isSkipLinks = true
        that.$router.push({
          path: '/loanIndex',
          query: {}
        })
      } else {
        const obj = {
          product_type: item.product_type,
          redirectName: item.name,
          isHiddenShare: true,
          needLogin: true
        }
        wakeApp(obj)
        // Toast('请下载悦淘APP，享更多优惠');
        // this.showCopyLink = true;
      }
    },
    // 复制跳转链接
    copyLink() {
      this.$router.push({
        path: '/JumpLink'
      })
    },
    // 关闭弹窗
    closeMask() {
      this.showCopyLink = false
    },
    // 分享
    configShare() {
      const shareInfo = {
        title: '重磅福利来啦！全国加油最低1元起、外卖天天可领、视频充值8折…', // 分享标题
        desc: '快和我一起免费领取吧>', // 分享描述
        link: 'https://web.yuetao.vip/homeIndex?codeNumber=' + window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-17/20/yuelvhui8drkr79XTe1605616133.png' // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #EF2E38;
}

.content {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #fff;
  padding: 150px 10px 10px 10px;
  /*margin-bottom: 30px;*/
  /*生活精选*/

  .lifeSelectWrap {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding: 55px 19px 20px 19px;

    .lifeSelectContent {
      width: 19%;
      margin-bottom: 15px;
      position: relative;

      .wxStyle {
        position: absolute;
        top: 0;
        left: 0;
      }

      .lifeSelectImg {
        width: 48px;
        height: 48px;
        margin-bottom: 5px;
      }

      .lifeSelectText {
        color: #7E4709;
        font-size: 12px;
        margin-bottom: 5px;
        /*width: 80px;*/
      }

      .lifeSelectDesc {
        color: #666666;

        span {
          font-size: 12px;
          transform: scale(0.9);
        }
      }
    }

    .lifeSelectContent:nth-child(5) {
      margin-left: auto;

      .lifeSelectText {
        width: 70px;
      }
    }

    .lifeSelectContent:nth-child(10) {
      margin-left: auto;
    }

    .lifeSelectContent_c {
      margin-right: 1%;
    }
  }

  /*连接icon*/

  .ConnectionServiceWrap {
    display: flex;
    margin-top: -15px;
    padding: 0 26px;

    .ConnectionServiceIcon {
      width: 7px;
      height: 30px;
    }

    .ConnectionServiceIcon:nth-child(2) {
      margin-left: auto;
    }
  }

  /*美食优惠*/

  .FoodPremiumWrap, .TravelServiceWrap {
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: left;
    padding: 17px;
    margin-top: 10px;

    .lifeServiceTitle {
      padding-left: 26px;
      background-repeat: no-repeat;
      background-position: left;
      background-size: 20px 20px;
      font-size: 15px;
      color: #7E4709;
      font-weight: 700;
    }

    .FoodPremiumContent {
      margin-top: 20px;

      .FoodPremiumList {
        display: flex;
        margin-top: 17px;

        .FoodPremiumListContent {
          position: relative;

          img {
            width: 104px;
            height: 57px;
          }

          .wxStyle {
            position: absolute;
            top: 0;
            left: 0;
            width: 113px;
            height: 63px;
          }
        }

        .FoodPremiumListContent1 {
          margin-left: 7px;
          margin-right: 7px;
        }
      }
    }
  }

  /*出行服务*/

  .TravelServiceWrap {
    margin-top: -10px;
  }

  /*生活服务*/

  .lifeServiceWrap {
    width: 100%;
    margin: 10px auto 20px auto;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 17px;
    text-align: left;

    .lifeServiceTitle {
      padding-left: 26px;
      background-repeat: no-repeat;
      background-position: left;
      background-size: 20px 20px;
      font-size: 15px;
      color: #7E4709;
      font-weight: 700;
    }

    .FoodPremiumIconList {
      margin-top: 20px;
    }

    .lifeServiceContent {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;

      .lifeServices {
        width: 49%;

        .lifeServicesOne {
          position: relative;

          .wxStyle {
            width: 170px;
            height: 123px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        .ServiceTitle {
          .ServiceTitleName {
            display: inline-block;
            color: #7E4709;
            font-weight: 700;
            font-size: 12px;
          }

          .ServiceTitleDesc {
            display: inline-block;
            color: #999999;
            font-weight: 700;
            /*margin-left: 7px;*/
            /*display: inline-block;*/
            /*transform: scale(0.5);*/
            font-size: 9px;
          }
        }

        .ServiceContent {
          width: 100%;
          height: 88px;
          margin-top: 7px;
        }
      }

      .lifeServices_m {
        margin-right: 2%;
      }
    }
  }
}

.van-dialog {
  background: none;
}

/*会员弹窗*/
.dia_box {
  padding: 10px;

  .codeTextStyle {
    color: #1f5dee;
    font-size: 12px;
    line-height: 15px;
    background: none;
    border: none;
  }

  .dai_title {
    padding: 10px 0;
    font-size: 17px;
    color: #333333;
    line-height: 16px;
    position: relative;
    text-align: center;

    .dialogBgc {
      position: absolute;
      width: 100%;
      height: 200px;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .dialogText {
      margin-top: 101px;

      .dialogText1 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 17px;
      }

      .dialogText2,
      .dialogText3 {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }

      .dialogText3 {
        margin-bottom: 9px;
      }
    }
  }

  .dia_tips {
    margin: 10px 0;
    color: #333333;
    font-size: 14px;
    line-height: 18px;
    text-align: left;

    & > b {
      color: #1f83ee;
    }
  }

  .dia_list {
    text-align: left;

    li {
      padding: 4px 30px;
      font-size: 13px;
      line-height: 18px;

      span {
        display: inline-block;
        width: 65px;
        text-align: left;
      }
    }
  }

  .dia_btn {
    display: flex;
    /*margin: 13px 0 10px;*/

    div {
      flex: 11;
      font-size: 16px;
      font-weight: 500;
      padding: 15px;
      background-color: #fff;
    }

    & > div:nth-child(1) {
      border-radius: 0 0 0 6px;
      color: #333333;
    }

    & > div:nth-child(2) {
      color: #e1c182;
      border-radius: 0 0 6px 0;
    }
  }
}

.ophead {
  /*height: 40px;*/
  height: 40px;
}

.ophead.vs {
  transition: height 0.6s ease-in-out;
  height: 0px;
  display: none;
}

/*    下载APP页面*/
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 99;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      // height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .sku {
    width: 100%;
    min-height: 500px;
    border-radius: 8px 8px 0 0;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 14px;

    .tp {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      .goods-cover {
        width: 78px;
        height: 78px;
        border-radius: 4px;
        background: #e0e0e0;
      }

      .goods-price {
        width: 250px;
        height: 78px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding-left: 12px;

        .pris {
          text-align: left;

          .hjy {
            color: #ed2e2e;
            font-size: 13px;
          }

          .hjy-count {
            color: #ed2e2e;
            font-size: 18px;
            font-weight: bold;
            margin-right: 12px;
          }

          .spe-icon {
            font-size: 14px;
            color: #ed2e2e;
            font-weight: bold;
            margin-left: 4px;
          }

          .gwj {
            color: #141f33;
            font-size: 13px;
          }
        }

        .guige {
          text-align: left;
          font-size: 13px;
          color: #99a0ad;
        }

        .close {
          width: 16px;
          height: 16px;
          background: url("../../assets/images/closesku.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }

    .out {
      height: 200px;
      overflow: hidden;
      margin-bottom: 50px;

      .inner {
        height: 200px;
        overflow: auto;
      }
    }

    .status-box {
      width: 100%;
      margin-bottom: 8px;

      .tits {
        font-size: 16px;
        color: #141f33;
        margin-bottom: 12px;
        text-align: left;
      }

      .status {
        width: 100%;
        overflow: hidden;

        .box {
          width: auto;
          padding: 6px 20px;
          background: #f0f2f5;
          border-radius: 4px;
          font-size: 14px;
          color: #141414;
          display: inline-block;
          margin-right: 12px;
          margin-bottom: 12px;
          // float: left;
        }

        .act {
          background: #fae9e8;
          color: #ed2e2e;
        }
      }
    }
  }

  .num-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #141f33;
    margin-bottom: 23px;

    .handles {
      .edge,
      .add {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
      }

      .cou {
        display: inline-block;
        width: 45px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
        margin: 0 4px;
      }
    }
  }

  .code-txt {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #99a0ad;
    margin-bottom: 4px;
  }

  .btn-box {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    .btn1 {
      width: 50%;
      height: 100%;
      line-height: 44px;
      background: #201e1d;
      color: #fff;
      font-size: 16px;
    }

    .btn2 {
      background: #ff001c;
    }

    .btn3 {
      width: 100%;
      height: 100%;
      line-height: 44px;
      color: #fff;
      font-size: 16px;
      background: #ff001c;
    }
  }

  /*    复制链接下载*/

  .showCopyLinkContent {
    position: absolute;
    height: auto;
    left: 50%;
    margin-left: -130px;
    top: 50%;
    margin-top: -200px;

    .showCopyLinkImg {
      width: 260px;
      height: 326px;
    }

    .showCopyLinkClose {
      width: 31px;
      height: 31px;
      margin-top: 29px;
    }
  }
}

.FoodPremiumIconList {
  display: flex;
  flex-wrap: wrap;

  .FoodPremiumIconListContent {
    width: 19%;
    text-align: center;
    margin-left: auto;
    position: relative;

    .wxStyle {
      position: absolute;
      top: 0;
      left: 0;
    }

    .lifeSelectImg {
      width: 49px;
      height: 49px;
      margin-bottom: 5px;
    }

    .lifeSelectText {
      color: #333333;
      font-size: 12px;
      margin-bottom: 5px;
      font-weight: 500;
      /*width: 80px;*/
    }

    .lifeSelectDesc {
      color: #666666;

      span {
        font-size: 12px;
        transform: scale(0.9);
      }
    }
  }
}

.wxStyles {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 50%;
  margin-left: -50px;
  background-color: red;
}
</style>
