<template>
  <div class="wxStyle">
    <wx-open-launch-app
      id="launch-btn"
      appid="wx735ebb8b05936d17"
      :extinfo="wxExtinfo"
      @error="opendata.handleError"
      @launch="opendata.handleLaunch"
    >
      <script type="text/wxtag-template">
        <style>.btn {
        width: 90px;
        height: 45px;
        font-size: 10px;
        }
        </style>
        <div class="btn">
        </div>
      </script>
    </wx-open-launch-app>
  </div>
</template>
<script>
export default {
  name: 'LocalLifeIndex',
  props: {
    opendata: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    wxExtinfo() {
      let redirectType = -1
      let str = ''

      if (this.opendata && this.opendata.wxExtinfo) {
        if (this.opendata.wxExtinfo.product_type) {
          switch (this.opendata.wxExtinfo.product_type) {
            case 1:
              redirectType = 3
              str = '?redirectType=3'

              break
            case 3:
              redirectType = 88
              str = '?redirectType=88'
              break
            case 4:
              redirectType = 95
              str = '?redirectType=95'
              break
            case 5:
              redirectType = 110
              str = '?redirectType=110'
              break
            case 6:
              redirectType = 94
              str = '?redirectType=94'
              break
            default:
              redirectType = this.opendata.wxExtinfo.product_type
              str = '?redirectType=' + this.opendata.wxExtinfo.product_type
          }

          if (this.options && this.opendata.wxExtinfo && this.opendata.wxExtinfo.id) {
            str += '&id=' + this.opendata.wxExtinfo.id
          }

          if (this.options && this.opendata.wxExtinfo && this.opendata.wxExtinfo.skuId) {
            str += '&skuId=' + this.opendata.wxExtinfo.skuId
          }

          if (this.options && this.opendata.wxExtinfo && this.opendata.wxExtinfo.name) {
            str += '&name=' + this.opendata.wxExtinfo.name
          }
          if (this.options && this.opendata.wxExtinfo && this.opendata.wxExtinfo.type) {
            str += '&type=' + this.opendata.wxExtinfo.type
          }
          if (this.options && this.opendata.wxExtinfo && this.opendata.wxExtinfo.channelId) {
            str += '&channelId=' + this.opendata.wxExtinfo.channelId
          }

          if (this.options && this.opendata.wxExtinfo && this.opendata.wxExtinfo.activityId) {
            str += '&activityId=' + this.opendata.wxExtinfo.activityId
          }
          if (this.options && this.opendata.wxExtinfo && this.opendata.wxExtinfo.orderSn) {
            str += '&orderSn=' + this.opendata.wxExtinfo.orderSn
          }
        }
      }
      console.log(redirectType)
      return 'https://www.yuetao.vip/ytApp/openPage' + str
    }
  },
  mounted() {

  },
  methods: {}
}
</script>
<style lang="less" scoped>
// .launch-btn{
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 1;
//     opacity: 0;
//     // background: red;
//   }
.wxStyle {
  width: 60px;
  height: 100px;
  /*background-color: red;*/
}

#launch-btn {
  width: 100px;
  height: 100px;
  /*background-color:#999999;*/
}
</style>
